.matrix table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .matrix th,
  .matrix td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 8px;
  }
  
  .matrix th {
    background-color: #2786b3;
  }
  
  .matrix td {
    height: 50px;
    width: 50px;
  }
  
  .matrix-container {
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  .matrix {
    overflow-x: auto;
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  th, td {
    padding: 12px;
    text-align: center;
    border: 1px solid #2c92ce;
  }
  th {
    background-color: #3d82d1;
    color: #000000;
    font-weight: bold;
  }
  td {
    background-color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  td:hover {
    background-color: #f0f8ff;
  }
  thead th {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .add-row-column {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .add-row-column input {
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1;
  }
  .add-row-column button {
    padding: 8px 16px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .add-row-column button:hover {
    background-color: #357abd;
  }