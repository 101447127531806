/* Conteneur principal */
.dynamic-page-container {
    max-width: 1200px;
    margin: 30px auto;
    padding: 20px;
    background-color: #f0f8ff;
    border-radius: 10px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  }
  
  /* En-tête */
  .dynamic-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 2px solid #82b1ff;
  }
  
  /* Titre */
  h1 {
    font-size: 26px;
    color: #1e88e5;
  }
  
  /* Boutons */
  .button {
    padding: 10px 16px;
    border: none;
    border-radius: 6px;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .button-home {
    background-color: #2196f3;
  }
  
  .button-home:hover {
    background-color: #1976d2;
  }
  
  .button-add {
    background-color: #64b5f6;
  }
  
  .button-add:hover {
    background-color: #42a5f5;
  }
  
  /* Champs de texte */
  .input-field {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #b0bec5;
    border-radius: 4px;
  }
  
  .input-field:focus {
    border-color: #1e88e5;
  }
  
  /* Tableau */
  .dynamic-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    padding: 12px;
    border: 1px solid #cfd8dc;
    text-align: center;
  }
  
  th {
    background-color: #e3f2fd;
    color: #1e88e5;
  }
  
  td:hover {
    background-color: #e1f5fe;
  }
  
  td[data-color="green"] {
    background-color: #81c784;
  }
  
  td[data-color="gray"] {
    background-color: #cfd8dc;
  }
  