/* CreatePage.css */

/* Conteneur principal */
.create-page-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Titre */
  h2 {
    font-size: 24px;
    color: #34495e;
    margin-bottom: 20px;
  }
  
  /* Champ de saisie */
  .input-field {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: calc(100% - 22px);
    margin-bottom: 15px;
    transition: border-color 0.3s;
  }
  
  .input-field:focus {
    border-color: #3498db;
    outline: none;
  }
  
  /* Bouton de création */
  .button-create {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    background-color: #27ae60;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .button-create:hover {
    background-color: #2ecc71;
    transform: translateY(-2px);
  }
  
  /* Message d'erreur */
  .error-message {
    color: #e74c3c;
    margin-top: 10px;
    font-size: 14px;
  }
  