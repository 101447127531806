/* HomePage.css */

/* Conteneur principal de la page d'accueil */
.home-page-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* En-tête de la page d'accueil */
  .home-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
  }
  
  /* Titre de la page */
  h1 {
    font-size: 26px;
    color: #2c3e50;
  }
  
  /* Bouton de création de page */
  .button-create {
    padding: 10px 15px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    background-color: #27ae60;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button-create:hover {
    background-color: #2ecc71;
  }
  
  /* Conteneur pour la liste des pages */
  .page-list-container {
    margin-top: 20px;
  }
  
  /* Liste des pages */
  .page-list {
    list-style: none;
    padding: 0;
  }
  
  /* Élément de la liste des pages */
  .page-item {
    margin: 10px 0;
  }
  
  /* Lien vers chaque page */
  .page-link {
    text-decoration: none;
    color: #3498db;
    font-size: 18px;
    transition: color 0.3s;
  }
  
  .page-link:hover {
    color: #2980b9;
    text-decoration: underline;
  }
  
  .button-report {
    background-color: #4CAF50; /* Couleur de fond */
    color: white; /* Couleur du texte */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px; /* Coins arrondis */
  }
  
  .button-report:hover {
    background-color: #45a049; /* Couleur au survol */
  }
  
  
  .button-report:disabled {
    background-color: #ddd;
    color: #888;
    cursor: not-allowed;
  }
  
  .button-report:hover:enabled {
    background-color: #45a049; /* Couleur au survol */
  }
  